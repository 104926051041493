<!--用户下载记录-->
<template>
    <div class="tools">
        <el-button @click="this.$router.go(-1)" :disabled="isLoading">{{lang['html.button.return']}}</el-button>
        <el-button @click="this.$router.go(0)" :disabled="isLoading">{{lang['html.button.refresh']}}</el-button>
        <el-button @click="outFileByUserDownload" :disabled="isLoading">{{lang['html.button.outFileByUserDownload']}}</el-button>
    </div>
    <el-table :data="tableData" border style="width: 100%" v-loading="isLoading">
        <el-table-column prop="id" label="Id" width="180" />
        <el-table-column prop="time" label="Time" width="230" />
        <el-table-column prop="username" label="UserName" width="230" />
        <el-table-column :prop="modelField" label="Model" />
    </el-table>
    <div style="text-align: left;display: inline">
        <el-pagination background
                       layout="total,prev, pager, next,sizes"
                       :total=total
                       style="margin-top: 10px"
                       v-model:currentPage="postData.page"
                       @current-change="changePage"

                       v-model:page-size="postData.listRows"
                       :page-sizes="[20,50,100,500,1000]"
                       @size-change="handleSizeChange"
        />
    </div>
</template>

<script>
import lib from "../../utils/common";

export default {
    name: "userDownLoad",
    data(){
        return {
            postData:{
                username:'',
                page:1,//当前页数
                listRows:20,//每页显示数量
            },
            total:-1,//记录总数
            tableData:[],
            init:false,//是否已经初始化
            isLoading:true,
            lang:this.$lang.getLang('user','userDownLoad'),
            modelField:this.$lang.getLanguage() === 'zh' ? 'model' : 'model_en',
        }
    },
    methods:{
        //获取下载记录总数
        getCount(){
            this.$notify(this.lang['getCount.wait']);
            this.$api.post('User.Download/getUserCount',this.postData).then(res=>{
                this.isLoading = false;
                let data = this.$helper.checkRes(res);
                if(!data){
                    this.$alert(this.lang['getCount.error']);
                    return;
                }
                let count = data.count ?? 0;
                this.total = count;
                if (!this.init){
                    this.init = true;
                    this.getTableData();
                }
            }).catch(err=>{
                this.isLoading = false;
                this.$helper.alertErrMsg(err);
            })
        },
        //获取下载记录
        getTableData(){
            this.$notify(this.lang['getTableData.wait'] + ' Page:' + this.postData.page);
            this.isLoading = true;
            this.$api.post('User.Download/getUserDownLoad',this.postData).then(res=>{
                this.isLoading = false;
                let data = this.$helper.checkRes(res);
                if (!data){
                    this.$alert(this.lang['getTableData.error.1']);
                    return;
                }
                let tableData = data.data;
                if (tableData == null){
                    this.$alert(this.lang['getTableData.error.2']);
                    return;
                }
                console.log('modelField',this.modelField)
                this.tableData = tableData;
            }).catch(err=>{
                this.isLoading = false;
                this.$helper.alertErrMsg(err);
            });
        },
        //页数变动
        changePage(page){
            this.postData.page = page;
            this.getTableData();
        },
        //每页显示数量变动
        handleSizeChange(size){
            this.postData.constructor = size;
            this.changePage(1);
        },
        //导出下载记录
        outFileByUserDownload(){
            this.$notify({message:this.lang['outFileByUserDownload.wait'],type:'info'});
            //生成记录
            let msg = '';
            let enter = '\r\n';
            for (let tableDataKey in this.tableData) {
                let temp = this.tableData[tableDataKey];
                let arr = [
                    temp.username,
                    temp.time,
                    temp.model,
                ]
                msg += arr.toString() + enter;
            }
            let header = ['username','time','model']
            let result = header.toString() + enter + msg;
            lib.downLoad(result, 'download.csv');
            //延迟提示,否则容易多个弹窗混乱
            setTimeout(() => {
                this.$message({
                    message: this.lang['outFileByUserDownload.successful'],
                    type: 'success',
                });
            }, 2000);
        },
    },
    mounted() {
        this.postData.username = this.$route.params.username;
        this.getCount();
    }
}
</script>

<style scoped>
.tools{
    margin-bottom: 10px;
}

</style>